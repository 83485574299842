.modal-content {
  backdrop-filter: var(--modal-content-backdrop-filter);
}
.provider {
  border: solid 1px var(--bs-border-color);
}
[data-bs-theme='dark'] {
  .modal-header {
    color: var(--bs-modal-color);
  }
  .modal-content{
    color: var(--bs-modal-color);
    background-color: var(--modal-content-bg-dark);
  }
}
