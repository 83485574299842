.input-group {
  &.input-group-xs {
    .btn-xs {
      height: 24px;
      padding: .4rem .7rem;

      .input-group-text {
        background: none;
        color: var(--white);
      }
    }

    .form-control-xs {
      @include font-size($input-font-size-sm);

      height: 24px;
      margin: 0;
      padding: $input-padding-y-sm $input-padding-y-sm;
    }
  }
}
