// THIS FILE CONTAINS ALL NON BOOTSTRAP CUSTOM VARIABLES
// ONLY AND ONLY AND I REPEAT ONCE AGAIN O-N-L-Y define css var() that can be used across the project to customize.
// if the variable is not defined will not break the build and will be simply ignored.
@import 'variables';
@import 'variables-dark';

$enable-button-gradients: true;

@at-root {
  .btn {
    --btn-gradient-secondary: linear-gradient(90deg, var(--bs-secondary), #fec301 50%, var(--bs-secondary));
    --btn-gradient-secondary-hover: linear-gradient(85deg, #fec301, var(--bs-secondary) 50%, #fec301);
  }

  mobile-prefix {
    --mobile-prefix-border-radius: .5rem 0 0 .5rem;
    //--mobile-prefix-border-radius: 1.23em;
    //--mobile-prefix-bg: #{lighten($primary, 12%)};
    --mobile-prefix-label: #1a2c4a;
    --mobile-prefix-color: #FFF;
  }


  .modal-content {
    --modal-content-backdrop-filter: blur(5px);
    --modal-content-bg-dark: #001435;
  }

  .accordion {
    --accordion-gap: 0.2rem;
    --accordion-bg-dark: var(--bs-dark-slate-blue);
    --accordion-header-font-family: 'MuseoSans-500', sans-serif;
  }

  header {
    --header-top-line-theme-switcher-color: #FFF;
    --header-btn-font-family: 'MuseoSans-Rounded-900', sans-serif;
    --header-logo-max-width: 160px;
    --header-logo-height: 3.5em;
    --header-nav-link-color: var(--bs-silver);
    --header-top-line-color: #000E26;
  }

  footer {
    --footer-headings-color: rgba(255, 255, 255, 0.5);
    --footer-paragraphs-color: rgba(255, 255, 255, 0.5);
    --footer-bg-image: url('../../assets/images/bridge.webp');

    --footer-social-icon-color: #A3A3A3;
    --footer-social-icon-width: 28px;
    --footer-social-icon-height: 28px;
    --footer-social-icon-margin: 1rem 0;

    --footer-theme-switcher-bg-color: #001435;
    // Comment this part if we want to stick with global accordion style
    .accordion {
      --bs-accordion-bg: var(--accordion-bg-dark);
      --bs-accordion-border-color: var(--accordion-bg-dark);
      --bs-accordion-active-bg: var(--accordion-bg-dark);
      --bs-accordion-active-color: #fff;
      --bs-accordion-color: #fff;
      --bs-accordion-btn-color: #fff;
      --accordion-color: #fff;
      --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23667286'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23667286'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
  }


  // Buttons
  .btn {
    --btn-primary-border-color: var(--bs-secondary);
    --btn-tertiary-color: #FFF;
    --input-button-bg: #{lighten($primary, 12%)};
    --btn-tertiary-bg: var(--bs-tertiary);
  }

  .btn-close {
    --btn-close-bg-color: #333;
    --btn-close-hover-bg: #333;
  }

  // Inputs
  .form-floating {
    --form-control-focus-color-dark: #FFF;
  }


  input, textarea, select {
    --input-bg-dark: #{lighten($body-bg-dark, 3%)};
    --input-border-radius: 1.23em;
    --input-check-border-checked-color: #FFF;
  }

  nav {
    --bs-nav-pills-border-radius: 1em;
    --navbar-dark-bg: #{lighten($body-bg-dark, 22%)};
  }

  .card {
    --card-bonus-code-bg: radial-gradient(177.24% 171.39% at 95.96% 0, #2AD0CA 0, #E1F664 22.92%, #FEB0FE 46.88%, #ABB3FC 68.23%, #5DF7A4 87.5%, #58C4F6 100%);
    --card-bonus-code-code-color: var(--bs-dark);
    --card-bonus-code-code-border: 1px solid var(--bs-dark);
    --card-promotion-header-color: #fff;
    --card-bonus-color: #fff;
  }

  section.registration {
    --registration-bg: #001435;
  }

  .faq {
    //--faq-card-bg: var(--bs-secondary);
  }
}
