$desktop-support: true;
$tablet-breakpoint: 576px;
$small-desktop-breakpoint: 768px;
$desktop-breakpoint: 1200px;

$zindex-list: base second overlay nav header alert error loader;

//$spacer: 1rem !default;

@mixin mobile {
  // <-- backward
  @media all and (max-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin tablet {

  // --> onward
  @if ($desktop-support) {
    @media all and (min-width: $tablet-breakpoint) {
      @content;
    }
  }
}

@mixin small-desktop {

  // --> onward
  @if ($desktop-support) {
    @media all and (min-width: $small-desktop-breakpoint) {
      @content;
    }
  }
}

@mixin desktop {

  // --> onward
  @if ($desktop-support) {
    @media all and (min-width: $desktop-breakpoint) {
      @content;
    }
  }
}

@mixin orientation-landscape {
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    @content;
  }
}

// Function: zindex()
// DESCRIPTION: use a list of z-index by name to avoid different  _variables.scss
// NOTE: see variables -> $zindex-list

@function zindex($element) {
  $multiplier: 5; // step + 5
  $z-index: index($zindex-list, $element);

  @if $z-index {
    @return $z-index * $multiplier;
  }

  @warn 'There are no item "#{$element}" in this list; choose one of: #{var(--zindex-list)}';
  @return null;
}

@mixin headings {
  h1, h2, h3, h4, h5, h6 {
    @content;
  }
}

@mixin paragraphs {
  p, small, span {
    @content;
  }
}
