//
// This files contains rules to override the normal variables of bootstrap scss
//
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&family=Rubik+Mono+One&display=swap');

$primary: #22222B;
$secondary: #FF230A;
$tertiary: #00dccd;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': green,
  'info': $tertiary,
  'warning': orange,
  'danger': red,
  'light': white,
  'dark': black
);

$custom-colors: (
  'tertiary': $tertiary,
  'header-bg': #252A2D,
  'footer-bg': #252A2D,
  'btn-accent': #5CB85C
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

$enable-shadows: false;
$btn-focus-box-shadow: none;
$min-contrast-ratio: 3.8;
$font-family-sans-serif: 'Roboto';
$font-family-monospace: 'Montserrat';
$headings-font-family: 'Rubik Mono One';
$body-bg-dark: #0f0f14;

$input-border-width: 0;
$input-bg: $primary;
$input-color: #FFF;
$input-focus-bg: $primary;
$input-focus-box-shadow: none;

$form-check-input-focus-box-shadow: none;
$form-check-input-width: 1.5em;
$form-switch-color: #FFF;
$form-switch-color-dark: #FFF;
$form-switch-checked-color: $secondary;
$form-switch-width: 2.5em;

$link-decoration: none;

$nav-pills-link-active-bg: $secondary;
$nav-pills-link-active-color: #000;
$nav-pills-border-radius: 2em;
$nav-pills-link-active-color: #FFF;

$accordion-button-active-bg: transparent;
$accordion-inner-border-radius: none;
$accordion-border-radius: none;
//$nav-link-color: #FFF;

//$card-border-width: none;

$modal-content-border-width: 0;

$btn-close-width: 40px;

.btn-close {
  background-size: $btn-close-width;
}

:root {
  [data-bs-theme="dark"] {
    --bs-link-color: var(--bs-body-color);
  }
}
