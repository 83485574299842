@import '../base/mixins';

.card {

  &-body {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: space-between;
    height: 100%;
  }

  .overlay-img {
    width: 120px;
    margin-bottom: 1rem;
  }


  &.bonus {
    color: var(--card-bonus-color);
  }

  .bonus-code:not(:empty) {
    background: var(--card-bonus-code-bg);
    border-radius: 8px;
    align-self: flex-start;
    padding: 6px;
    margin-bottom: 1rem;

    .code {
      font-size: 12px;
      text-transform: uppercase;
      border: var(--card-bonus-code-code-border);
      border-radius: 6px;
      color: var(--card-bonus-code-code-color);
      padding: 12px;
    }
  }

  .img-container:not(:empty) {
    .background-img {
      width: 100%;
    }

    + .card-header,
    + .card-body {
      position: absolute;
      left: 0;
      right: 0;
      --bs-card-border-width: none;
    }
  }

  .btn {
    font-weight: 700;
    text-transform: uppercase;
  }

  &.promotion {
    //.card {
    &-body {
      h5 {
        color: var(--card-promotion-header-color);
      }

      padding: 24px 16px;
    }

    //}
  }

  &.sport {
    .card-header {
      color: var(--bs-white);
      text-shadow: 2px 2px 3px #000000;
      text-align: center;
    }

    .header {
      margin-top: 10px;
      font-size: 35px;
      font-weight: 600;
    }

    .subheader {
      margin-top: 15px;
      font-size: 20px;
    }
  }

  .nav-pills {
    .nav-link {
      border: 1px solid var(--bs-secondary);

      &:not(.active){
        border: 1px solid var(--bs-silver);
      }
    }
  }
}

@include desktop {
  .card.promotion .card-body {
    padding: 32px 24px 24px 32px;
  }
}


//.card {
//  border-color: var(--border-color-light);
//  border-radius: .25rem;
//
//  &-header {
//    background: var(--primary);
//    color: var(--contrast);
//  }
//
//  &-title {
//    color: var(--text);
//    font-size: 16px;
//    font-weight: bold;
//    line-height: 1.5;
//  }
//
//  &-subtitle,
//  &-description {
//    color: var(--text);
//    font-size: 14px;
//    line-height: 1.5;
//  }
//
//  &-status {
//    font-size: 12px;
//    left: 8px;
//    padding: 6px;
//    position: absolute;
//    top: 8px;
//  }
//
//  &-subtitle {
//    color: #A3A3A3;
//  }
//}
//
//@include small-desktop {
//  .card {
//
//    &-title {
//      overflow: hidden;
//      text-overflow: ellipsis;
//      white-space: nowrap;
//      width: 100%;
//    }
//
//    &-description {
//      max-height: 64px;
//      overflow-y: hidden;
//    }
//
//    &-image-top {
//      height: 144px;
//
//      > img {
//        height: 100%;
//      }
//    }
//  }
//}
