// ----------------------------------------------------------------
//	Sports Color Attribution
// -----------------------------------------------------------------

// If Sport Category  has no color attribute
$default-sport-color: #00FFB5;

$available-sport-color: #084A4D;
$favourites-color: #FFBA26;
$today-events-color: #3D6B23;

$slots-color: #FF0086;
$table-games-color: #0019FF;

$aviation-color: #FF003D ;
$lucky-numbers-color: #E2FF00;
$virtuals-color: #00FFFF;
$other-betting-games: #6900FF;

$american-football-color: hsl(32, 17%, 36%);
$aussie-rules-color: #286B30;
$badminton-color: #B54C4F;
$bandy-color: #678DC2;
$baseball-color: #A342C9;
$basketball-color: hsl(44, 100%, 38%);
$beach-soccer-color: #BC9E75;
$beach-volleyball-color: #BCA863;
$bowls-color: #1B201B;
$boxing-color: #C93539;
$counter-strike-color: #837243;
$cricket-color: hsl(74, 29%, 29%);
$curling-color: #BCD9B9;
$cycling-color: hsl(50, 70%, 46%);
$darts-color: #E7C46F;
$e-sports-color: #F27B7F;
$fantasy-color: #9488A1;
$field-hockey-color: #506252;
$floorball-color: #4550A3;
$futsal-color: hsl(201, 69%, 35%);
$golf-color: #3D6B23;
$handball-color: hsl(201, 51%, 29%);
$ice-hockey-color: hsl(199, 18%, 57%);
$league-of-legends-color: #98BEBE;
$mma-color: #C23734;
$motorsport-color: hsl(2, 66%, 53%);
$olympics-color: #B6EED6;
$pesapallo-color: #EC7D6D;
$rugby-color: hsl(165, 48%, 35%);
$sailing-color: #317089;
$snooker-color: hsl(338, 66%, 38%);
$soccer-color: hsl(160, 53%, 41%);
$specials-color: hsl(193, 58%, 40%);
$squash-color: #E9A488;
$starcraft-color: #5F422A;
$table-tennis-color: #3352F2;
$tennis-color: #FF7F50;
$volleyball-color: hsl(32, 24%, 54%);
$waterpolo-color: #8EB0F2;
$winter-sports-color: #F27B7F;

$sport-colors: (
  american-football: $american-football-color,
  aussie-rules: $aussie-rules-color,
  aussie_rules: $aussie-rules-color,
  badminton: $badminton-color,
  bandy: $bandy-color,
  baseball: $baseball-color,
  basketball: $basketball-color,
  beach-soccer: $beach-soccer-color,
  beach-volleyball: $beach-volleyball-color,
  bowls: $bowls-color,
  boxing: $boxing-color,
  counter-strike: $counter-strike-color,
  cricket: $cricket-color,
  curling: $curling-color,
  cycling: $cycling-color,
  darts: $darts-color,
  default: $default-sport-color,
  e-sports: $e-sports-color,
  fantasy: $fantasy-color,
  fantasy-basketball: $fantasy-color,
  fantasy-soccer: $fantasy-color,
  fantasy-tennis: $fantasy-color,
  field-hockey: $field-hockey-color,
  floorball: $floorball-color,
  futsal: $futsal-color,
  golf: $golf-color,
  handball: $handball-color,
  ice-hockey: $ice-hockey-color,
  league-of-legends: $league-of-legends-color,
  mma: $mma-color,
  motorsport: $motorsport-color,
  olympics: $olympics-color,
  pesapallo: $pesapallo-color,
  rugby-union: $rugby-color,
  sailing: $sailing-color,
  snooker: $snooker-color,
  soccer: $soccer-color,
  specials: $specials-color,
  squash: $squash-color,
  starcraft: $starcraft-color,
  table-tennis: $table-tennis-color,
  tennis: $tennis-color,
  volleyball: $volleyball-color,
  waterpolo: $waterpolo-color,
  winter-sports: $winter-sports-color,
);


:root {
  @each $color, $value in $sport-colors {
    --#{$color}: #{$value};
  }
}
