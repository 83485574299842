@use 'sass:map';
@import './variables';
@import './mixins';


$webkit-scrollbar-track-bg-color: var(--bs-primary);
$webkit-scrollbar-height: 10px;
$webkit-scrollbar-width: 10px;
$webkit-scrollbar-track-bg-color: var(--bs-primary);
$webkit-scrollbar-thumb-bg-color: var(--bs-secondary);
$webkit-scrollbar-thumb-border-color: var(--bs-primary);
$webkit-scrollbar-thumb-border-width: 2px;
$webkit-scrollbar-thumb-border-radius: 8px;
$webkit-scrollbar-thumb-bg-color-hover: var(--bs-tertiary);

* {
  &::-webkit-scrollbar-track {
    background-color: var(--bs-primary)
  }

  &::-webkit-scrollbar {
    height: $webkit-scrollbar-height;
    width: $webkit-scrollbar-width;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $webkit-scrollbar-thumb-bg-color;
    border: $webkit-scrollbar-thumb-border-width solid $webkit-scrollbar-thumb-border-color;
    border-radius: $webkit-scrollbar-thumb-border-radius;

    &:hover {
      background-color: $webkit-scrollbar-thumb-bg-color-hover;
    }
  }

}
