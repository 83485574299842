$select-background: url(escape-svg('data:image/svg+xml,<svg viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#{$form-select-indicator-color}" d="M 7.1547363,12.814005 0.35261031,6.011875 c -0.4701471,-0.47014 -0.4701471,-1.23038 0,-1.69554 L 1.4829643,3.185995 c 0.470146,-0.47016 1.230384,-0.47016 1.695529,0 L 8.0000001,8.0075051 12.821507,3.185995 c 0.470148,-0.47016 1.230384,-0.47016 1.69553,0 l 1.130352,1.13034 c 0.470148,0.47016 0.470148,1.2304 0,1.69554 l -6.8021245,6.80213 c -0.4601444,0.47016 -1.2203819,0.47016 -1.6905282,0 z"/></svg>'));
$pre-floating-background: #FFF;
$pre-floating-box-shadow: 1px 1px 2px #111;

select {
  appearance: none;
  background: $select-background no-repeat calc(100% - 15px);
  background-size: 10px;

  &.form-select-xs {
    background: $select-background no-repeat calc(100% - 1px);
    background-size: 8px;
    padding: 0 9px 0 2px;
  }
}

pre {
  font: 12px monospace;

  &.floating {
    background-color: $pre-floating-background;
    border-radius: 5px;
    box-shadow: $pre-floating-box-shadow;
    max-width: 30vw;
    overflow: auto;
    padding: 10px;
    position: fixed;
    right: 10px;
    text-overflow: ellipsis;
    top: 10px;
    z-index: 100;
    max-height: calc(100vh - 20px);

    &.left {
      right: auto;
      left: 10px;
    }
  }
}
