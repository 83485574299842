@use 'sass:map';
@import '../base/mixins';
@import '../base/variables';


.header-top-line {
  background-color: var(--header-top-line-color);
}

header {
  background-color: var(--bs-header-bg);
  position: sticky;
  top: 0;
  z-index: zindex(header);

  a {

    img {
      height: var(--header-logo-height);
    }
  }

  .dropdown-toggle {
    color: var(--header-top-line-theme-switcher-color);
  }

  .btn {
    text-transform: uppercase;
    font-family: var(--header-btn-font-family);
  }
}
