//* {
//  transition: background-color .5s ease-in-out, opacity .5s linear;
//}
//
//html {
//}
//
//body {
//  height: 100%;
//
//  p {
//    line-height: 1.5rem;
//  }
//
//  &.transitioning {
//    transition: all 3s ease-out !important;
//  }
//}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
//
//iframe {
//  border: 0;
//  color-scheme: light;
//}
//
//a {
//  color: var(--bs-white);
//  transition: background-color .3s ease-in-out, color .3s ease-in-out;
//
//  &:focus {
//    outline: none;
//  }
//}
//
//a:not([href]) {
//  text-decoration: none;
//
//  &:hover {
//    text-decoration: none;
//  }
//}
//
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
//
////:root {
////  [data-bs-theme='dark'] {
////    --bs-link-color: var(--bs-body-color);
////    --bs-header-bg: #001435;
////    --bs-footer-bg: #001435;
////  }
////}
