@use 'sass:map';
@import '../base/variables';
@import '../base/mixins';

footer {
  background: var(--bs-footer-bg) var(--footer-bg-image) 50% / contain;

  @include headings {
    color: var(--footer-headings-color)
  }
  @include paragraphs {
    color: var(--footer-paragraphs-color)
  }

  .disclaimer {
    font-size: 0.8rem;
    text-align: center;
  }

  .license-paragraph {
    font-size: 0.8rem;
    text-align: center;
  }


  &-payments {
    svg {
      filter: grayscale(100%);
    }
  }

  .footer-social {
    flex-wrap: wrap;

    &-icon {
      fill: var(--footer-social-icon-color);
      height: var(--footer-social-icon-height);
      margin: var(--footer-social-icon-margin);
      transition: fill .2s ease;
      width: var(--footer-social-icon-width);

      &:hover {
        transform: scale(120%);
      }
    }
  }

  .theme-switcher {
    .fill-current {
      fill: var(--footer-paragraphs-color) !important;

    }

    .form-check-input:checked {
      background-color: var(--footer-theme-switcher-bg-color);
      border-color: var(--footer-paragraphs-color);
    }

  }

  language-selector {


    .dropdown-toggle {
      color: var(--footer-paragraphs-color);

      span {
        color: var(--footer-paragraphs-color);
      }
    }

    .dropdown-item {
      span {
        color: var(--bs-primary);

      }
    }

  }

  .version {
    color: var(--footer-paragraphs-color);
  }
}


[data-bs-theme='dark'] {

  language-selector {

    .dropdown-item {
      span {
        color: var(--bs-white);

      }
    }

  }
}
