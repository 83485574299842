.btn {
  @if $enable-button-gradients {
    &-secondary {
      &.btn-gradient {
        background-image: var(--btn-gradient-secondary);
        border: transparent;
        padding: calc(var(--bs-btn-padding-y) + 1px) var(--bs-btn-padding-x);

        &.active,
        &:hover {
          background-image: var(--btn-gradient-secondary-hover);
        }
      }
    }
  }
  &.btn-xs {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);

    font-size: var(--bs-btn-font-size);
    line-height: .8;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  }
}


.btn-outline-primary {
  --bs-btn-border-color: rgba(#{var(--bs-primary-rgb), .45});
  box-shadow: none;
}

.btn-outline-secondary {
  --bs-btn-border-color: rgba(#{var(--bs-secondary-rgb), .45});
  box-shadow: none;
}
