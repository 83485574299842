:root {
  .accordion {
    gap: var(--accordion-gap);

    .accordion-item {
      h2 {
        font-family: var(--accordion-header-font-family);
        color: var(--bs-accordion-color);

      }

      small {
        color: var(--bs-accordion-color);
      }
    }
  }
}

[data-bs-theme='dark'] {
  .accordion {
    --bs-accordion-bg: var(--accordion-bg-dark);
    --bs-accordion-active-bg: var(--accordion-bg-dark);
    --bs-accordion-border-color: var(--accordion-bg-dark);
  }
}
