// reset tags, main reusable rules
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.trigger {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.pane {
  overflow: auto;

  &-y {
    overflow-y: auto;
  }

  &-x {
    overflow-x: auto;
  }

  &.small-bar {
    &::-webkit-scrollbar {
      //display: none;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border: 1px solid var(--bs-secondary);
    }
  }

  &.no-bar {
    -ms-overflow-style: none; //IE and Edge
    scrollbar-width: none; //Firefox
  }
}

label.required {
  &::after {
    color: var(--error);
    content: '*';
  }
}

.fill {
  &-white {
    fill: var(--bs-white);
  }

  &-primary {
    fill: var(--bs-primary);
  }

  &-dark {
    fill: var(--text);
  }

  &-gold {
    fill: #F2C94C;
  }

  &-light {
    fill: #CECECE;
  }
}

.full-screen-loader {
  align-items: center;
  background: rgba(#000, .3);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/** overriding bootstrap classes in dark theme, and other styles */

//.registration-form {
//  field-error.error {
//    margin-bottom: 0;
//  }
//
//  :is(.geo-infos) {
//    field-error.error {
//      margin-bottom: 20px;
//    }
//  }
//}

.vh-100-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh - 2 * #{var(--nav-height-mobile)});
}

//.banner-wrapper {
//  align-items: center;
//  background-position: center;
//  background-repeat: no-repeat;
//  background-size: cover;
//  display: flex;
//  height: 80px;
//}

//@include mobile {
//  a,
//  h1,
//  h2,
//  h3,
//  h4,
//  h5,
//  h6,
//  p,
//  span,
//  strong {
//    word-break: break-word;
//  }
//}


//@include small-desktop {
//  .heading-title {
//    font-size: 30px;
//  }
//
//  .vh-100-center {
//    justify-content: center;
//    min-height: calc(100vh - #{var(--header-height-desktop)});
//  }
//
//  .save-data-btn {
//    width: auto;
//  }
//
//  .accordion-light {
//    .tab-accordion-label {
//      font-size: 14px;
//    }
//  }
//
//}

//SVG
//--------------------------
svg {
  height: 16px;
  width: 16px;
}

.flag-icon {
  border-radius: 50%;
  height: 20px;
  margin-right: 5px;
  width: 20px;

  svg {
    transform: translate(-8px, -6px);
  }
}

.font-small-1 {
  font-size: .7rem !important;
  //for Feather SVG icons as we are using base size as 1rem
  @at-root svg#{&} {
    height: .7rem !important;
    width: .7rem !important;
  }
}

.font-small-2 {
  font-size: .8rem !important;

  @at-root svg#{&} {
    height: .8rem !important;
    width: .8rem !important;
  }
}

.font-small-3 {
  font-size: .9rem !important;

  @at-root svg#{&} {
    height: .9rem !important;
    width: .9rem !important;
  }
}

.font-small-4 {
  font-size: 1rem !important;
  @at-root svg#{&} {
    height: 1rem !important;
    width: 1rem !important;
  }
}

.fill-current {
  fill: currentcolor !important;
}

.fill-white {
  fill: var(--bs-white) !important;
}

.fill-black {
  fill: var(--bs-black) !important;
}

.fill-gray {
  fill: var(--bs-gray-500) !important;
}

@each $color, $value in $theme-colors {
  .fill-#{$color} {
    fill: $value !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus { // fit to remove autofill
  transition: background-color 0s 600000s, color 0s 600000s;
}

ul, ol {
  list-style: none;
}

//-------------- Z-index --------------
.zindex-0 {
  z-index: 0 !important;
}

.zindex-1 {
  z-index: 1 !important;
}

.zindex-2 {
  z-index: 2 !important;
}

//-------------- positions --------------
.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.left-auto {
  left: auto !important;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.fixed-height-dropdown {
  min-width: 290px;

  .dropdown-menu {
    max-height: 280px;
    overflow-y: auto;
  }
}

*[class^="rotate-"] {
  transition: transform .5s;
}
.rotate-90 {
  transform: rotate(90deg);
}

.rotate-90-inverse {
  transform: rotate(-90deg);
}

//@include media-breakpoint-up(lg) {
//.lg-position-absolute {
//position: absolute;
//right: 0;
//top: 10px;
//}
//}
