//@import 'base/variables';
//@import 'base/mixins';
//
//* {
//  box-sizing: inherit;
//
//  &::before,
//  &::after {
//    box-sizing: inherit;
//  }
//
//  &:focus {
//    outline: none;
//  }
//}
//
//html {
//  box-sizing: border-box;
//  scroll-behavior: smooth;
//}
//
//body {
//  font: normal 400 16px 'Roboto', sans-serif;
//
//  strong {
//    font-style: normal;
//    font-weight: 700;
//  }
//
//
//}
//
//app-root {
//  background-color: var(--main-bg);
//  display: flex;
//  flex-direction: column;
//  min-height: 100vh;
//
//  &.no-scroll {
//    position: fixed;
//    width: 100%;
//  }
//
//  @include orientation-landscape {
//    bottom-bar {
//      display: none !important;
//    }
//
//    .games {
//      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
//
//      game-tile .game {
//        flex-direction: column;
//
//        .favourites-icon {
//          inset: 40px 10px auto auto;
//        }
//      }
//    }
//
//    main > section {
//      overflow-x: hidden;
//    }
//
//    .nav-main, .sidebar-nav {
//      height: calc(100vh - var(--nav-height-mobile));
//    }
//  }
//}
//
main {
  min-height: calc(100vh - 200px);
//  display: flex;
//  flex: 1 0 auto;
//  min-height: 320px;
//  position: relative;
//
//
//  > section {
//    display: flex;
//    flex: 1 1 auto;
//
//    > section {
//      flex: 1 1 auto;
//      flex-direction: column;
//      min-height: calc(100vh - #{var(nav-height-mobile)});
//      padding: 10px;
//    }
//  }
}
//
//#launcher {
//  display: none;
//}
//
//:root {
//  @each $color, $value in $light-theme {
//    --#{$color}: #{$value};
//  }
//
//  @each $size, $value in $sizes {
//    --#{$size}: #{$value};
//  }
//
//}
//
//.dark {
//  @each $color, $value in $dark-theme {
//    --#{$color}: #{$value};
//  }
//}
//
//@include mobile {
//  main {
//    > section {
//      max-width: 100%;
//      min-height: calc(100vh - #{var(--nav-height-mobile)});
//    }
//  }
//}
//
//@include tablet {
//  main {
//    min-height: 480px;
//  }
//}
//
//@include small-desktop {
//
//  main {
//    > section {
//      min-height: calc(100vh - #{var(--header-height-desktop)});
//    }
//  }
//
//  #launcher {
//    display: block;
//  }
//}
