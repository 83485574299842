@import './variables';
@import 'bootstrap/scss/mixins';

.grid {
  display: grid;
  gap: 5px;
  grid-auto-flow: dense;
  grid-template-columns: 1fr 1fr;
  //@include media-breakpoint-up(xs) {
  &.xs,
  &.gx-1 {
    grid-template-columns: 1fr;
  }

  &.lg {
    grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
  }
  //}
}

@include media-breakpoint-up(sm) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));

    &.sm {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    &.xs {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
  }
}

@include media-breakpoint-up(lg) {
  .grid {
    &.lg {
      gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    }

    &.xl {
      gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
  }
}
