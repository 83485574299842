@use 'sass:map';
@import './variables';
@import './mixins';



table {
  &.tournaments {
    border-collapse: collapse;
    border-spacing: 0;

    tr {
      transition: background-color .2s ease;

      th,
      td {
        font-size: 13px;
        height: 36px;
        padding: 0 5px;
        position: relative;
      }

      td {
        min-width: 45px;
        padding: 0 8px;

        &.large {
          text-align: left;
          width: 100%;
        }
      }

      &:hover {
        td {
          background-color: var(--secondary-light);
        }
      }

      &:first-child {
        th {
          background-color: var(--secondary);
          border-right: 1px solid var(--border-color);
        }
      }

      &:nth-child(2) {
        th {
          background-color: var(--secondary-light);

          //&:first-child {
          //  text-align: center;
          //}
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }

    td {
      background-color: var(--contrast);
      border-bottom: 1px solid var(--border-color);

      &:not(:last-child) {
        border-right: 1px solid var(--border-color);
      }

    }
  }



  //
  //  &.dtb {
  //    display: flex;
  //    flex-direction: column;
  //
  //    td.hide-mobile,
  //    thead {
  //      display: none;
  //    }
  //
  //    tr {
  //      display: flex;
  //      flex-direction: column;
  //
  //      &:hover {
  //        background-color: transparent;
  //      }
  //
  //      td {
  //        align-items: center;
  //        border-top: none;
  //        display: flex;
  //        font-size: 12px;
  //        justify-content: space-between;
  //      }
  //    }
  //  }
}

//
//.inline-events {
//  display: flex;
//  flex-direction: column;
//
//  tr {
//    border: 1px solid var(--border-color-light);
//    border-radius: .25rem;
//    display: block;
//    padding: 10px 5px;
//
//    &:hover {
//      td {
//        background-color: transparent !important;
//      }
//    }
//
//    &:nth-child(-n+2) {
//      display: none;
//    }
//
//    &:not(:last-child) {
//      margin-bottom: 6px;
//    }
//
//    td {
//      border: none;
//
//      &:not(:last-child) {
//        border-right: none;
//      }
//
//      &.match-id,
//      &.start-date,
//      &.name {
//        display: none !important;
//      }
//
//      &.jackpot-wrapper {
//        display: flex;
//        flex: 1;
//        flex-direction: column;
//        height: 100%;
//        margin-bottom: 16px;
//
//        .jackpot {
//          &-id,
//          &-sport,
//          &-name {
//            display: block;
//          }
//
//          &-id,
//          &-sport {
//            font-size: 12px;
//          }
//
//          &-id {
//            font-weight: 600;
//            margin-bottom: 8px;
//          }
//
//          &-name {
//            color: var(--text);
//            font-size: 14px;
//            font-weight: bold;
//            margin-bottom: 5px;
//          }
//
//          &-sport {
//            // We haven't got this as variable
//            color: #A3A3A3;
//          }
//        }
//      }
//
//      &.odd-jackpot {
//        padding: 0;
//        width: 100%;
//
//        &:not(:last-child) {
//          .btn-light {
//            margin-right: 5px;
//          }
//        }
//
//        .btn-light {
//          background-color: var(--border-color-light);
//          width: calc(33vw - 12px);
//
//          &:disabled {
//            background-color: var(--border-color-light);
//          }
//
//        }
//      }
//    }
//  }
//}
//
@include tablet {
  table {
//
//    &.table-responsive {
//      display: table;
//    }
//
    &.tournaments {

      td {
        background-color: var(--contrast);

        &.match-id,
        &.start-date,
        &.name {
          display: table-cell;
        }

        &.odd {
          min-width: 80px;

          button {

            &:disabled {
              background-color: var(--contrast);
            }

            &.active {
              background-color: var(--secondary);
            }
          }
        }
      }
    }
//
//    &.dtb {
//      display: table;
//
//      thead {
//        display: table-header-group;
//      }
//
//      .mobile-only {
//        display: none;
//      }
//
//      tr {
//        display: table-row;
//
//        &:hover {
//          background-color: rgba(0, 0, 0, .075);
//        }
//
//        td,
//        td.hide-mobile {
//          border-top: 1px solid var(--border-color-light);
//          display: table-cell;
//          font-size: 16px;
//          vertical-align: middle;
//        }
//      }
//    }
//  }
//
//  .inline-events {
//    tr {
//      align-items: center;
//      display: flex;
//      padding: 12px;
//
//      td {
//        &.jackpot-wrapper {
//          flex: 1 0 50%;
//          margin-bottom: 0;
//        }
//
//        &.odd-jackpot {
//
//          &:not(:last-child) {
//            margin-right: 2px;
//          }
//
//          .btn-light {
//            height: 43px;
//            width: 100%;
//
//            &:disabled {
//              background-color: var(--border-color-light);
//            }
//          }
//        }
//      }
//    }
  }
}


