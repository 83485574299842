// Fonts
@font-face {
  font-family: 'MuseoSans-Rounded-1000';
  src: url('../../assets/fonts/museosansrounded-1000-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans-Rounded-900';
  src: url('../../assets/fonts/museosansrounded-900-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

//@font-face {
//  font-family: 'MuseoSans-Rounded-700';
//  src: url('../../assets/fonts/MuseoSansRounded-700.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}

//@font-face {
//  font-family: 'MuseoSans-Rounded-500';
//  src: url('../../assets/fonts/MuseoSansRounded-500.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}


//@font-face {
//  font-family: 'MuseoSans-Rounded-300';
//  src: url('../../assets/fonts/MuseoSansRounded-300.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}

//@font-face {
//  font-family: 'MuseoSans-Rounded-100';
//  src: url('../../assets/fonts/MuseoSansRounded-100.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}

//@font-face {
//  font-family: 'MuseoSans-500';
//  src: url('../../assets/fonts/MuseoSans_500.otf') format('opentype');
//  font-weight: normal;
//  font-style: normal;
//}

@include mobile {
  h1 {
    font-size: 2.1875em;
  }

  h2 {
    font-size: 1.5em;

  }

  h3 {
    font-size: 1.375em;

  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.9375em;
  }
  h6 {
    font-size: 1.125em;
  }
}
