.btn-close {
  background: transparent;
  border-radius: 50%;
  border: 2px solid white;
  font-size: 30px;
  width: 35px;
  height: 35px;
  color: var(--bs-secondary);
  position: absolute;
  right: 0;
  line-height: 1;
  padding: 0;
  font-family: monospace;
  vertical-align: middle;

  &:hover {
    border-color: var(--bs-secondary);
  }
}

//[data-bs-theme='dark'] {
//.btn-close {
//--bs-btn-close-color: white;
//}
//}

