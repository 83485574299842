input, textarea, select{
  border-radius: var(--input-border-radius);
}

.btn-tertiary {
  color: var(--btn-tertiary-color);
  background-color: var(--btn-tertiary-bg);
}

.pin {
  input {
    border-radius: 0;
  }
}

form.stepped {
  label {
    color: var(--bs-body-bg);
  }
}


[data-bs-theme='dark'] {

  .form-floating {
    label::after {
      background-color: transparent !important;
    }
  }

  .form-control, .form-select {
    //background-color: var(--bs-primary);
    color: var(--form-control-focus-color-dark);

    &::placeholder {
      color: var(--form-control-focus-color-dark);
    }
  }

  .form-check-input {
    &:checked {
      border-color: var(--input-check-border-checked-color);
    }
  }

}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
}
