//@import '../base/variables';

.nav:not(.nav-pills) {
  .nav-link {
    &.active {
      color: var(--bs-secondary) !important;
    }
  }
}

.navbar-nav {
  &.header {
    background-color: transparent;
    border: none;
  }

  .nav-link {
    color: var(--header-nav-link-color, var(--bs-body-color));
    border-radius: 1rem;

    .icon {
      fill: var(--bs-silver);
    }

    &:hover {
      color: var(--bs-secondary);
      transition: color 0.3s ease;

      .icon {
        fill: var(--bs-secondary);
      }
    }

    &.active {
      font-weight: bold;
      color: var(--bs-tertiary) !important;
      background-color: transparent;

      .icon {
        fill: var(--bs-tertiary);
      }
    }
  }

  li {
    a {
      padding: 1em 0.8em;
    }
  }

  &.side {

    li {
      border-bottom: 1px solid var(--bs-silver);

      &:last-child {
        border-bottom: none;
      }
    }
  }

}

//.nav-tabs {
//  .nav-link {
//    border: 1px solid var(--bs-silver);
//  }
//}
